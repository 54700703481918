"use strict";
// Class definition

var KTBootstrapMarkdown = function () {    
    // Private functions
    var demos = function () {
        
    }

    return {
        // public functions
        init: function() {
            demos(); 
        }
    };
}();

// Initialization
jQuery(document).ready(function() {
    KTBootstrapMarkdown.init();
});